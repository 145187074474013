import React from 'react';
import * as styles from './index.module.scss';
import Modal from 'react-modal';
import cx from 'classnames';
import { IFlybus } from '../../interfaces';
import { icelandairlogo, reLogo } from '../../icons';

interface IExternalProps {
  modalOpen: boolean;
  hashReturn: string;
  userFlybus: IFlybus | null;
}

interface IProps extends IExternalProps {
  lang: string;
}

const CheckoutBoxModal: React.FunctionComponent<IProps> = (props) => {
  const { modalOpen, hashReturn, userFlybus } = props;

  function generateReturn(orderId: string, lastName: string, hash: string) {
    const url = `https://icelandair.com/manage-booking?orderId=${orderId}&lastName=${lastName}&lang=${userFlybus?.LanguageId}&flybusHash=${hash}`;
    dispatchIcelandair();
    return url;
  }
  function dispatchIcelandair() {
    localStorage.removeItem('flybusData');
  }
  if (modalOpen && hashReturn && userFlybus) {
    setTimeout(function () {
      window.location.replace(
        generateReturn(
          userFlybus.OrderId,
          userFlybus.LastName ?? '',
          hashReturn
        )
      );
    }, 3000);
  }

  return (
    <Modal
      isOpen={modalOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      closeTimeoutMS={200}
      className={styles.modal}
      bodyOpenClassName={styles.modalOpen}
      portalClassName={styles.modalPortal}
      ariaHideApp={false}
    >
      <div className={styles.modal}>
        <div>
          <img src={reLogo} className={styles.relogo} alt='' />

          <img src={icelandairlogo} className={styles.icelandairLogo} alt='' />
        </div>
        <div className='columns is-mobile is-marginless'>
          <div className={cx('column', styles.center)}>
            <h1>Thank you!</h1>
            <br />
            <p>Now we will forward you back to Icelandair</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckoutBoxModal;
