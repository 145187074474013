// extracted by mini-css-extract-plugin
export var container = "checkoutCart-module--container--1rDvN";
export var mainTitle = "checkoutCart-module--mainTitle--1Ywfu";
export var titleContainer = "checkoutCart-module--titleContainer--1rFSX";
export var subheader = "checkoutCart-module--subheader--dYzTE";
export var checkoutList = "checkoutCart-module--checkoutList--1IKja";
export var deleteFromCart = "checkoutCart-module--deleteFromCart--3ejXv";
export var image = "checkoutCart-module--image--3Pxc5";
export var dividerGray = "checkoutCart-module--dividerGray--3cb7Y";
export var dividerGrayLong = "checkoutCart-module--dividerGrayLong--1jb-K";
export var subtitle = "checkoutCart-module--subtitle--3DONQ";
export var summary = "checkoutCart-module--summary--2bHJD";
export var tourdetailsInfo = "checkoutCart-module--tourdetailsInfo--1zI9o";
export var priceBreakDown = "checkoutCart-module--priceBreakDown--27mC8";
export var bold = "checkoutCart-module--bold--2ez_Z";
export var price = "checkoutCart-module--price--2wmB5";
export var priceLineThrough = "checkoutCart-module--priceLineThrough--282si";
export var finalPrice = "checkoutCart-module--finalPrice--15P-7";
export var finalPriceLineThrough = "checkoutCart-module--finalPriceLineThrough--3aMhf";
export var priceWithDiscount = "checkoutCart-module--priceWithDiscount--386F3";
export var priceDiscount = "checkoutCart-module--priceDiscount--gsCco";
export var input = "checkoutCart-module--input--2lJAC";
export var promoCodeButton = "checkoutCart-module--promoCodeButton--1pcil";
export var promoErrorMessage = "checkoutCart-module--promoErrorMessage--2oXk9";
export var checkoutQuestions = "checkoutCart-module--checkoutQuestions--11YeC";
export var checkoutButton = "checkoutCart-module--checkoutButton--1rD4K";
export var extraInfo = "checkoutCart-module--extraInfo--3GD0t";
export var checkoutTotalPrice = "checkoutCart-module--checkoutTotalPrice--2G2q1";
export var bookingId = "checkoutCart-module--bookingId--2sOtt";
export var redirectText = "checkoutCart-module--redirectText--3zRZM";
export var totalPrice = "checkoutCart-module--totalPrice--7iF_6";
export var buttonSection = "checkoutCart-module--buttonSection--a-sem";
export var buttonMobileStyle = "checkoutCart-module--buttonMobileStyle--3_LRI";
export var mainContactDetails = "checkoutCart-module--mainContactDetails--3HzhI";
export var textInput = "checkoutCart-module--textInput--27iyW";
export var divider = "checkoutCart-module--divider--1hZhG";
export var couponInput = "checkoutCart-module--couponInput--1TweQ";
export var discountText = "checkoutCart-module--discountText--2U_29";
export var label = "checkoutCart-module--label--T9G_r";
export var required = "checkoutCart-module--required--3cUSe";
export var link = "checkoutCart-module--link--vwRkH";
export var headerPadding = "checkoutCart-module--headerPadding--1fvdK";
export var tableLine = "checkoutCart-module--tableLine--25C-U";
export var errorField = "checkoutCart-module--errorField--3tvCl";
export var checkoutError = "checkoutCart-module--checkoutError--2UwyX";
export var paymentSuccessContainer = "checkoutCart-module--paymentSuccessContainer--1Dofe";
export var paymentSuccess = "checkoutCart-module--paymentSuccess--4lPgR";
export var successIcon = "checkoutCart-module--successIcon--3yIBi";
export var icelandairLogo = "checkoutCart-module--icelandairLogo--1HTQL";
export var icelandairLogoMobile = "checkoutCart-module--icelandairLogoMobile--1rAti";
export var fortArrow = "checkoutCart-module--fortArrow--5uJck";
export var successTitle = "checkoutCart-module--successTitle--3kU4o";
export var centerContent = "checkoutCart-module--centerContent--1G_7a";
export var overlay = "checkoutCart-module--overlay--1RsnX";
export var productHeader = "checkoutCart-module--productHeader--3sgba";