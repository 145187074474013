import React from 'react';
import Cards, { CallbackArgument } from 'react-credit-cards';
import cx from 'classnames';

import * as styles from './CheckoutCreditCard.module.scss';
import 'react-credit-cards/es/styles.scss';
import { visaMasterCard } from '../../icons';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from './utils';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';
import { IReducers, ITranslationsCheckout } from '../../interfaces';

interface IExternalProps {
  name: string;
  number: string;
  validNumber: boolean;
  expiry: string;
  validExpiry: boolean;
  cvc: string;
  validCvc: boolean;
  issuer: string;
  isMobile?: boolean;
  locale: string;
  //  focused: 'number' | 'name' | 'cvc' | 'expiry';
  validCreditCardInfo: boolean;
  handleCreditCardCallback(type: CallbackArgument, isValid: boolean): void;
  setCardProp(event: React.FocusEvent<HTMLInputElement>): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsCheckout;
}

type IFocused = 'number' | 'name' | 'cvc' | 'expiry';
interface IState {
  focused: IFocused;
}

class CheckoutCreditCard extends React.Component<IProps, IState> {
  readonly state: IState = {
    focused: 'name',
  };
  constructor(props: IProps) {
    super(props);
  }
  render() {
    const {
      cvc,
      expiry,
      name,
      number: cardNumber,
      issuer,
      handleCreditCardCallback,
      isMobile,
      validNumber,
      validExpiry,
      validCvc,
      validCreditCardInfo,
      localeData,
    } = this.props;
    const { focused } = this.state;
    return (
      <>
        {isMobile ? null : (
          <React.Fragment>
            <div className='columns'>
              <div className={cx('column', styles.creditCardWrapper)}>
                <Cards
                  number={cardNumber}
                  name={name}
                  expiry={expiry}
                  cvc={cvc}
                  focused={focused}
                  callback={handleCreditCardCallback}
                />
              </div>
            </div>
            <div className='columns'>
              <div className='column'>
                <p>{localeData.weAcceptCardsText}</p>
                <img
                  src={visaMasterCard}
                  alt='Visa and Mastercard'
                  height={45}
                />
              </div>
            </div>
            <div />
          </React.Fragment>
        )}
        <div className='columns is-multiline'>
          {isMobile ? (
            <React.Fragment>
              <div className={cx('column is-full', styles.centerCard)}>
                <div className={cx('columns', styles.inputContainer)}>
                  <div>
                    <div className='column'>
                      <p>{localeData.weAcceptCardsText}</p>
                      <img
                        src={visaMasterCard}
                        alt='Visa and Mastercard'
                        height={45}
                      />
                    </div>
                    <div className={cx('column', styles.creditCardWrapper)}>
                      <Cards
                        number={cardNumber}
                        name={name}
                        expiry={expiry}
                        cvc={cvc}
                        focused={focused}
                        callback={handleCreditCardCallback}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}
          <form className='colums'>
            <div className='column'>
              <label
                htmlFor='cardName'
                className={cx(styles.label, styles.required)}
              >
                {localeData.cardName}
              </label>
              <input
                id='cardName'
                className={cx(styles.textInput, 'fs-block')}
                type='text'
                name='name'
                required={true}
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </div>
            <div className='column'>
              <label
                className={cx(styles.label, styles.required)}
                htmlFor='cardNumber'
              >
                {localeData.cardNumber}
              </label>
              <input
                id='cardNumber'
                className={cx(styles.textInput, 'fs-block', {
                  [styles.error]: !validNumber && !validCreditCardInfo,
                })}
                type='tel'
                name='number'
                pattern='[\d| ]{16,22}'
                required={true}
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              {cardNumber.substring(0, 1) === '3' ? (
                <div className={styles.errorPadding}>
                  <p className={styles.errorField}>
                    {localeData.doNotAcceptAmericanExpress}
                  </p>
                </div>
              ) : null}
              {cardNumber.substring(0, 1) === '6' ? (
                <div className={styles.errorPadding}>
                  <p className={styles.errorField}>
                    {localeData.doNotAcceptDiscover}
                  </p>
                </div>
              ) : null}
            </div>
            <div className='column'>
              <div className={isMobile ? 'columns is-mobile is-6' : 'columns'}>
                <div className='column is-half'>
                  <label
                    className={cx(styles.label, styles.required)}
                    htmlFor='expiredate'
                  >
                    {localeData.cardExpiry}
                  </label>
                  <input
                    id='expiredate'
                    className={cx(styles.textInput, 'fs-block', {
                      [styles.error]: !validExpiry,
                    })}
                    type='tel'
                    name='expiry'
                    pattern='\d\d/\d\d'
                    required={true}
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className='column is-half'>
                  <label
                    className={cx(styles.label, styles.required)}
                    htmlFor='securityCode'
                  >
                    {' '}
                    {localeData.cardCvc}
                  </label>
                  <input
                    id='securityCode'
                    className={cx(styles.textInput, 'fs-block', {
                      [styles.error]: !validCvc,
                    })}
                    type='tel'
                    name='cvc'
                    pattern='\d{3,4}'
                    required={true}
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
              </div>
            </div>

            <input type='hidden' name='issuer' value={issuer} />
          </form>
        </div>
      </>
    );
  }

  handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      focused: event.target.name as IFocused,
    });
  };

  handleInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const { number: cardNumber, expiry, cvc, issuer, name } = this.props;
    const allValues = { cardNumber, expiry, cvc, issuer, name };
    if (event.target.name === 'number') {
      event.target.value = formatCreditCardNumber(event.target.value);
    } else if (event.target.name === 'expiry') {
      event.target.value = formatExpirationDate(event.target.value);
    } else if (event.target.name === 'cvc') {
      event.target.value = formatCVC(event.target.value, allValues);
    }
    this.props.setCardProp(event);
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;

  return { locale };
};
export default connect(mapStateToProps)(
  ({
    cvc,
    expiry,
    name,
    number,
    issuer,
    handleCreditCardCallback,
    isMobile,
    validNumber,
    validExpiry,
    validCvc,
    validCreditCardInfo,
    setCardProp,
    locale,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query CheckoutCreditCard {
          allContentfulTranslationsCheckout {
            edges {
              node {
                ...TranslationsCheckout
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <CheckoutCreditCard
            localeData={
              data.allContentfulTranslationsCheckout.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            cvc={cvc}
            expiry={expiry}
            name={name}
            number={number}
            issuer={issuer}
            handleCreditCardCallback={handleCreditCardCallback}
            isMobile={isMobile}
            validNumber={validNumber}
            validExpiry={validExpiry}
            validCvc={validCvc}
            validCreditCardInfo={validCreditCardInfo}
            setCardProp={setCardProp}
            locale={locale}
          />
        );
      }}
    />
  )
);
