import React from 'react';
import ls from 'local-storage';
import moment from 'moment';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import { CallbackArgument } from 'react-credit-cards';

import { camelize, keyByIItem, validatePhoneNumber } from '../utils';

import { CartApi, BookingApi, CurrencyApi, ProductApi } from '../api';
import {
  IPageContext,
  ICart,
  IQuestions,
  IQuestionAnswer,
  IBooking,
  IBookingError,
  ICurrencyOption,
  IReducers,
  ICardInfo,
  IConfirmBooking,
  DataType,
  IAnalytics,
  IAnalyticsItem,
  ICurrency,
  IGQQuery,
  IReduceBookingQuestion,
  IQuestionArray,
  IBookingQuestionsState,
  ISelectValue,
  IConfirmBookingLine,
  IBookingLineExtra,
  IMainContactQuestions,
  IQuestionAnswers,
  IPromoError,
  IError,
  ISEO,
  IMainContact,
  IPhoto,
  ITranslationsCheckout,
  IFlybus,
  ITranslationsCart,
  ICountryOption,
} from '../interfaces';
import Meta from '../components/Meta/Meta';
import {
  Loader,
  Link,
  CheckBox,
  CheckoutQuestions,
  Icon,
  Title,
  BackgroundImage,
  Button,
} from '../components';
import { windowSizes } from '../constants';
import { TranslationContextConsumer } from '../context';
import * as styles from './styles/checkoutCart.module.scss';
import { connect } from 'react-redux';
import { setCart, resetCart } from '../redux/actions/cartActions';
import CheckoutCreditCard from '../components/CheckoutCreditCard/CheckoutCreditCard';
import {
  formatPrice,
  getQueryVariable,
  clearQueryParameters,
  setQueryParameterNoRefresh,
  isBooking,
  validateEmail,
  checkInputType,
} from '../utils';
import withWindowSize, { IWindowSizeProps } from '../hocs/withWindowSize';
import { successCheck, fortArrow, fortArrows, icelandairlogo } from '../icons';
import { ferdagjofinFlag } from '../utils/featureFlags';
import CheckoutBoxModal from '../components/CheckoutBoxModal/CheckoutBoxModal';
import TransportCancelButton from '../../src/BookingEngine/components/TransportCancelButton';

const TRANSPORT = 'TRANSPORT';
const XSTART = 'X -';

declare global {
  interface Window {
    dataLayer: object[];
  }
}

interface IExternalProps {
  pageContext: IPageContext;
  currency: ICurrencyOption;
  cartObject: ICart | null;
  locale: string;
  lang: string;
  setCart(cart: ICart): void;
  resetCart(): void;
  isIcelandair: boolean;
}

interface IContentfulCategories {
  allContentfulCouponErrorMessages: IGQQuery<{
    node_locale: string;
    expired: string;
    invalidTravelTime: string;
    notApplicableToCart: string;
    notFound: string;
    fallbackMessage: string;
    [key: string]: string;
  }>;
  allContentfulTour: IGQQuery<{
    productNumber: string;
    slug: string;
    productId: string;
    category: Array<{ title: string }>;
    analyticsCategory: { title: string };
  }>;
}

interface IProps extends IExternalProps {
  data: IContentfulCategories;
  localeData: ITranslationsCheckout;
  localeData2: ITranslationsCart;
}

interface IState {
  hashReturn: string;
  isLoading: boolean;
  couponLoading: boolean;
  questions: IQuestions | null;
  bookingQuestions: IBookingQuestionsState | null;
  cartId: string;
  contactDetails: IQuestionAnswers | null;
  checkedBooking: IBooking | null;
  bookingError: IBookingError | null;
  name: string;
  number: string;
  validCredit: boolean;
  expiry: string;
  validExpiry: boolean;
  cvc: string;
  validCvc: boolean;
  issuer: string;
  focused: 'number' | 'name' | 'cvc' | 'expiry';
  agreeTerms: boolean;
  agreeError: boolean;
  signUpNewsLetter: boolean;
  couponCode: string;
  giftCardCode: string;
  usdRate: ICurrency | null;
  validCreditCardInfo: boolean;
  cartItemsArray: [];
  promoErrorMessage: string | null;
  giftCardErrorMessage: string | null;
  useThreeDS: boolean;
  submitting: boolean;
  finalYayPrice: number;
  userFlybus: IFlybus | null;
  hashString: string | null;
  dropdownOpen: boolean;
  sagaClubNumber: string;
  validSaga: boolean;
  selectedCountryCode: ICountryOption | null;
}

class CheckoutCart extends React.Component<IProps & IWindowSizeProps, IState> {
  cartApi = new CartApi();
  bookingApi = new BookingApi();
  productApi = new ProductApi();
  currencyApi = new CurrencyApi();

  contactDetails: HTMLDivElement | null = null;
  paymentDetails: HTMLDivElement | null = null;
  bookableExtras: HTMLDivElement | null = null;

  readonly state: IState = {
    isLoading: false,
    couponLoading: false,
    questions: null,
    bookingQuestions: null,
    cartId: '',
    contactDetails: null,
    checkedBooking: null,
    name: '',
    number: '',
    validCredit: true,
    expiry: '',
    validExpiry: true,
    cvc: '',
    validCvc: true,
    issuer: '',
    focused: 'name',
    agreeTerms: false,
    agreeError: false,
    signUpNewsLetter: false,
    couponCode: '',
    giftCardCode: '',
    bookingError: null,
    usdRate: null,
    validCreditCardInfo: true,
    cartItemsArray: [],
    promoErrorMessage: null,
    giftCardErrorMessage: null,
    useThreeDS: true,
    submitting: false,
    finalYayPrice: -1,
    userFlybus: null,
    hashString: '',
    dropdownOpen: false,
    hashReturn: '',
    sagaClubNumber: '',
    validSaga: false,
    selectedCountryCode: null,
  };

  setCartItemsArray(cart: any) {
    if (!!cart && !!cart.Items) {
      const arr: any = [];
      cart.Items.forEach((item: any) => {
        arr.push({ id: item.Product.Id, name: item.Product.Name });
      });
      this.setState({ cartItemsArray: arr });
    }
  }

  // lodash omit replacement
  omit(list: number[]) {
    const omittedBookingQuestionsArray = this.state.bookingQuestions
      ? Object.keys(this.state.bookingQuestions)
          .filter((key) => !list.includes(parseInt(key, 10)))
          .map(
            (key) => ({
              [key]: this.state.bookingQuestions
                ? this.state.bookingQuestions[key]
                : null,
            }),
            {}
          )
      : null;
    return omittedBookingQuestionsArray
      ? Object.assign(
          {},
          ...omittedBookingQuestionsArray.map((item) => ({
            [Object.keys(item)[0]]: Object.values(item)[0],
          }))
        )
      : null;
  }

  async componentDidMount() {
    const cartId: string = ls.get('cartId');
    const { cartObject, currency, lang, localeData, isIcelandair } = this.props;
    const threeDS = await this.cartApi.shouldUse3dSecure();
    this.setState({ useThreeDS: threeDS });

    const isIcelandairQuerry: boolean = getQueryVariable('headers')
      ? true
      : false;
    if (isIcelandair || isIcelandairQuerry) this.setCacheFlybusData();

    if (cartObject) {
      if (ferdagjofinFlag) {
        const cart2 = await this.cartApi.getCartByCheckout(
          cartId,
          currency.value,
          lang
        );
        if (cart2) {
          this.setState({ finalYayPrice: cart2.options[0].amount });
        }
      }
      this.setCartItemsArray(cartObject);
    }

    this.setState({ isLoading: true });
    const usdRate = await this.currencyApi.getCurrencyExchange('USD'); // afhverju er bara check 'a usd og hvað er þetta check að gera???
    if (usdRate) {
      const rate = usdRate.find((x) => x.CurrencyCode === 'USD');
      if (rate) {
        this.setState({ usdRate: rate });
      }
    }

    const bookingNo = getQueryVariable('bookingId');
    const processPayment = getQueryVariable('valid');
    const processPaymentError = getQueryVariable('error');

    let checkedBooking = null;

    if (bookingNo) {
      const invoice = await this.bookingApi.getBooking(
        bookingNo,
        currency.value,
        lang
      );
      if (invoice) {
        checkedBooking = invoice;
        const l = !!processPayment && processPayment === 'true';
        this.setState({ checkedBooking: invoice, isLoading: l });
      }

      if (
        !!processPayment &&
        processPayment === 'true' &&
        !!invoice &&
        invoice.Status === 'RESERVED'
      ) {
        this.confirmReservedBooking(
          bookingNo,
          false,
          currency.value,
          null,
          lang
        );
      } else if (
        !!processPaymentError &&
        processPaymentError === 'true' &&
        !!invoice &&
        invoice.Status === 'RESERVED' &&
        !!invoice.BookingNumber &&
        !!invoice.Currency
      ) {
        const bookingError = {
          error: {
            BookingNumber: bookingNo,
            DetailedErrorMessage: '',
            ErrorCode: '',
            ErrorMessage: localeData.cardNotVerified,
          },
        };

        // move reseved booking back to the cart
        const cart = await this.cartApi.moveBackToCart(
          ls.get('cartId'),
          invoice.BookingNumber,
          invoice.Currency
        );

        if (cart) {
          this.props.setCart(cart);
        }

        this.setCacheFlybusData();
        if (this.state.userFlybus) {
          this.setExternal(
            bookingError.error.BookingNumber,
            this.state.userFlybus.PartnerBookingNumber,
            bookingError.error.ErrorMessage,
            this.state.userFlybus.OrderId,
            '',
            ''
          );
        }

        this.setState({ bookingError });
      }
    }

    if (!cartObject) {
      this.setState({ isLoading: true });
      const cart = await this.cartApi.getCart(cartId, currency.value, lang);
      if (cart) {
        this.props.setCart(cart);
      }
    }
    const questions = await this.cartApi.getCheckoutQuestions(cartId);
    if (questions) {
      const contactInfo = checkedBooking ? checkedBooking.MainContact : null;
      this.setQuestionStateObject(questions, contactInfo);
    }
    this.setState({ questions, cartId, isLoading: false });
  }

  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { cartObject, lang } = this.props;

    if (ferdagjofinFlag) {
      if (
        this.state.finalYayPrice === -1 &&
        this.state.cartId &&
        this.state.finalYayPrice !== prevState.finalYayPrice
      ) {
        const cart2 = await this.cartApi.getCartByCheckout(
          this.state.cartId,
          this.props.currency.value,
          lang
        );
        if (cart2) {
          this.setState({ finalYayPrice: cart2.options[0].amount });
        }
      }
    }

    if (prevProps.cartObject !== this.props.cartObject && !!cartObject) {
      this.setCartItemsArray(cartObject);
      if (this.state.cartId && ferdagjofinFlag) {
        const cart2 = await this.cartApi.getCartByCheckout(
          this.state.cartId,
          this.props.currency.value,
          lang
        );
        if (cart2) {
          this.setState({ finalYayPrice: cart2.options[0].amount });
        }
      }
    }
    if (prevProps.currency !== this.props.currency) {
      const cart = await this.cartApi.getCart(
        this.state.cartId,
        this.props.currency.value,
        lang
      );
      if (cart) {
        this.props.setCart(cart);
      }
    }

    if (JSON.stringify(prevProps.cartObject) !== JSON.stringify(cartObject)) {
      const cartId: string = ls.get('cartId');
      if (
        !!prevProps.cartObject &&
        !!cartObject &&
        !!prevProps.cartObject.Items &&
        !!cartObject.Items
      ) {
        const oldIds = prevProps.cartObject.Items.map((i) => i.CartItemId);
        const newIds = cartObject.Items.map((i) => i.CartItemId);
        const list = oldIds.filter((x) => !newIds.includes(x));
        this.setState({
          bookingQuestions: this.omit(list),
        });
      } else if (
        !!prevProps.cartObject &&
        !!prevProps.cartObject.Items &&
        !!cartObject &&
        !cartObject.Items
      ) {
        const oldIds = prevProps.cartObject.Items.map((i) => i.CartItemId);
        this.setState({
          bookingQuestions: this.omit(oldIds),
        });
      }
      const questions = await this.cartApi.getCheckoutQuestions(cartId);
      if (
        !!questions &&
        !!questions.MainContactDetails &&
        !!prevState.questions &&
        !!prevState.questions.MainContactDetails &&
        questions.MainContactDetails !== prevState.questions.MainContactDetails
      ) {
        const oldQuestions = prevState.questions.MainContactDetails.map(
          (q) => q.Id
        );
        const newQuestions = questions.MainContactDetails.map((q) => q.Id);
        const list = oldQuestions
          .filter((x) => !newQuestions.includes(x))
          .map((y) => parseInt(y, 10));
        this.setState({
          bookingQuestions: this.omit(list),
        });
      }
    }
  }
  getLabelName(label: string) {
    switch (label) {
      case 'firstName':
        return this.props.localeData.firstName;
      case 'lastName':
        return this.props.localeData.lastName;
      case 'email':
        return this.props.localeData.yourEmailAddress;
      case 'phoneNumber':
        return this.props.localeData2.phoneNumber;
      case 'nationality':
        return this.props.localeData2.nationality;
      default:
        break;
    }
  }
  setCacheFlybusData() {
    const storageUserFlybus = localStorage.getItem('flybusData');
    //set state contactDetails
    const userFlybusData = storageUserFlybus
      ? JSON.parse(storageUserFlybus)
      : null;
    if (userFlybusData) {
      this.setState({ userFlybus: userFlybusData });
    }
  }
  updateUserFlybus(
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    currency: string | null
  ): void {
    const { userFlybus } = this.state;
    if (userFlybus && firstName && lastName && email && currency) {
      const newUserFlybus: IFlybus = {
        FirstName: firstName,
        LastName: lastName,
        OrderId: userFlybus.OrderId,
        Email: email,
        LanguageId: this.props.lang,
        Currency: currency,
        Travelers: null,
        PartnerBookingNumber: userFlybus.PartnerBookingNumber,
      };
      this.setState({ userFlybus: newUserFlybus });
    }
  }
  setQuestionStateObject(
    questions: IQuestions,
    contactInfo: IMainContact | null
  ) {
    if (!!questions && !!questions.MainContactDetails) {
      this.props.localeData;
      this.props.localeData2;
      if (questions.MainContactDetails) {
        const obj = questions.MainContactDetails.reduce((acc, val) => {
          const key = val.Id;
          if (
            !!key &&
            val.Id &&
            !acc[key] &&
            (val.Required || val.Id == 'phoneNumber')
          ) {
            acc[key] = {
              ...val,
              value: contactInfo
                ? contactInfo[key.charAt(0).toUpperCase() + key.slice(1)]
                : null,
              selectValue: null,
              time: null,
              isValid: true,
              Label: this.getLabelName(key) || val.Label,
            };
          }
          if (this.state.userFlybus) {
            const { FirstName, LastName, Email } = this.state.userFlybus;
            switch (key) {
              case 'firstName':
                FirstName != '' ? (acc[key].value = FirstName) : null;
                break;
              case 'lastName':
                LastName != '' ? (acc[key].value = LastName) : null;
                break;
              case 'email':
                Email != '' ? (acc[key].value = Email) : null;
                break;
              default:
                break;
            }
          }
          return acc;
        }, {} as IQuestionAnswers);
        this.setState({ contactDetails: obj });
      }
    }
    if (!!questions && !!questions.BookingLines) {
      const arr: IReduceBookingQuestion[] = [];
      questions.BookingLines.map((line) => {
        if (!!line && !!line.Questions && !!line.CartItemId) {
          line.Questions.map((q) =>
            q.Id
              ? arr.push({
                  ...q,
                  extra: null,
                  perBooking: true,
                  productId: line.CartItemId,
                  categoryId: 0,
                  type: 'Booking',
                  bookingId: parseInt(q.Id, 10),
                  value: null,
                  selectValue: null,
                  time: null,
                  isValid: true,
                })
              : null
          );
        }
        if (!!line && !!line.Guests && !!line.CartItemId) {
          line.Guests.map((q, i) => {
            if (!!q && !!q.Extras) {
              q.Extras.map((e) => {
                if (!!e && !!e.Questions) {
                  e.Questions.map((s) => {
                    if (e.BookingId) {
                      arr.push({
                        ...s,
                        extra: e.Title,
                        perBooking: false,
                        productId: line.CartItemId,
                        categoryId: 0,
                        type: 'Bookable extras',
                        bookingId: e.BookingId,
                        value: null,
                        selectValue: null,
                        time: null,
                        isValid: true,
                      });
                    }
                  });
                }
              });
            }
            if (!!q && !!q.Questions) {
              q.Questions.map((p) => {
                arr.push({
                  ...p,
                  extra: null,
                  bookingId: q.BookingId,
                  perBooking: false,
                  productId: line.CartItemId,
                  categoryId: q.Id,
                  type: 'Passenger ' + (i + 1) + ' (' + q.Type + ')',
                  value: null,
                  selectValue: null,
                  time: null,
                  isValid: true,
                });
              });
            }
          });
        }
      });
      const allQuestions = arr.reduce((acc, val) => {
        const key = val.productId;
        if (!!key && !acc[key]) {
          acc[key] = [val];
        } else if (!!key && !!acc[key]) {
          acc[key].push(val);
        }
        return acc;
      }, {} as IQuestionArray);
      const newObj: IBookingQuestionsState = {};
      Object.keys(allQuestions).map((key) => {
        if (!!key && !!allQuestions[key]) {
          const values = allQuestions[key].reduce((acc, val) => {
            const valKey = val.type;
            if (!!valKey && !acc[valKey]) {
              acc[valKey] = [val];
            } else if (!!valKey && !!acc[valKey]) {
              acc[valKey].push(val);
            }
            return acc;
          }, {} as IQuestionArray);
          newObj[key] = values;
        }
      });
      if (!!newObj && Object.keys(newObj).length > 0) {
        this.setState({ bookingQuestions: newObj });
      }
    }
    return null;
  }

  resetQuestionState() {
    const { contactDetails } = this.state;
    if (contactDetails) {
      const resetObject = { ...contactDetails };
      Object.keys(contactDetails).map((key) => {
        resetObject[key].value = null;
        resetObject[key].time = null;
        resetObject[key].selectValue = null;
        resetObject[key].isValid = true;
      });
      this.setState({ contactDetails: resetObject });
    }
  }

  getSlug(cartItems: any, product: any) {
    let result = 'tour/';
    let productId = '';
    cartItems.forEach((item: any) => {
      if (item.Product.Name === product.Product) {
        productId = item.Product.Id;
      }
    });
    for (const edge of this.props.data.allContentfulTour.edges) {
      if (!!edge.node.productId && !!productId) {
        if (
          edge.node.productId.toString() === productId.toString() &&
          !!edge.node.productId
        ) {
          result += edge.node.slug;
          break;
        }
      }
    }
    return result;
  }

  render() {
    const {
      isLoading,
      checkedBooking,
      bookingError,
      couponLoading,
      bookingQuestions,
      questions,
    } = this.state;
    const { cartObject, localeData, localeData2, isIcelandair } = this.props;
    const overlayStyle = {
      display: isLoading || couponLoading ? 'block' : 'none',
    };

    return (
      <React.Fragment>
        {/** I think we can remove translation context TODO */}
        <TranslationContextConsumer>
          {() => (
            <div
              className={cx(
                'centered-content columns is-multiline',
                styles.container
              )}
            >
              <CheckoutBoxModal
                modalOpen={this.state.dropdownOpen}
                hashReturn={this.state.hashReturn}
                userFlybus={this.state.userFlybus}
                lang={this.props.lang}
              />
              <div className={styles.overlay} style={overlayStyle}>
                <Loader size='large' />
              </div>
              {!!checkedBooking &&
              isBooking(checkedBooking) &&
              checkedBooking.Status === 'CONFIRMED' &&
              !!checkedBooking.Invoice ? (
                <React.Fragment>
                  <div className='column is-full'>
                    <h1 className={styles.mainTitle}>
                      {localeData.confirmation}
                    </h1>
                    <h2 className={styles.bookingId}>
                      {localeData.bookingId +
                        ' ' +
                        checkedBooking.BookingNumber}
                    </h2>
                  </div>
                  <div className='column is-full'>
                    <div className='columns'>
                      <div className='column is-6'>
                        {this.renderConfirmationText()}
                      </div>
                    </div>
                  </div>
                  <div className='column is-4'>
                    {this.renderCheckoutItems(checkedBooking)}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {!!checkedBooking &&
                  checkedBooking.Status === 'RESERVED' &&
                  !cartObject ? (
                    this.renderCheckoutItems(checkedBooking)
                  ) : !!cartObject && !!cartObject.Items ? (
                    <div className='column is-4-desktop is-full-tablet'>
                      {this.renderCartItems()}
                    </div>
                  ) : (
                    <div>
                      {!isLoading ? (
                        <span>
                          <p>{localeData.emptyCart}</p>
                          <p style={{ marginTop: '10px' }}>
                            <Link
                              langPath={this.props.pageContext.langPath}
                              to='tours-activities'
                              className={styles.link}
                            >
                              {localeData.goBack}
                            </Link>
                          </p>
                        </span>
                      ) : null}
                    </div>
                  )}
                  <div
                    className={cx(
                      'column is-4-desktop is-offset-2-desktop is-full-tablet',
                      styles.checkoutQuestions
                    )}
                  >
                    {!!cartObject &&
                    !!cartObject.Items &&
                    !!this.state.contactDetails ? (
                      <CheckoutQuestions
                        mainContactQuestions={this.state.contactDetails}
                        changeMainContactDetails={this.changeMainContactDetails}
                        mainContactHeader={localeData.mainContact}
                        changeSelectedCountryCode={
                          this.changeSelectedCountryCode
                        }
                        selectedCountryCode={this.state.selectedCountryCode}
                      />
                    ) : null}
                    {!!bookingQuestions &&
                    Object.keys(bookingQuestions).length > 0 &&
                    !!questions
                      ? this.renderBookingQuestions(bookingQuestions, questions)
                      : null}
                    {(!!checkedBooking &&
                      checkedBooking.Status === 'RESERVED') ||
                    (!!cartObject && !!cartObject.Items)
                      ? this.renderCardInfo(
                          !!bookingError && !!bookingError.error.ErrorMessage
                            ? bookingError.error.ErrorMessage
                            : ''
                        )
                      : null}
                  </div>
                </React.Fragment>
              )}
              {isIcelandair ? (
                <div>
                  <img
                    src={icelandairlogo}
                    className={
                      this.props.windowWidth <= windowSizes.header
                        ? styles.icelandairLogoMobile
                        : styles.icelandairLogo
                    }
                    alt='icelandair logo'
                  />
                </div>
              ) : null}
            </div>
          )}
        </TranslationContextConsumer>
      </React.Fragment>
    );
  }

  validateCreditCard() {
    const { validCredit, issuer, cvc } = this.state;
    const splitExpiry = this.state.expiry.split('/');
    const thisMonth = parseInt(moment(new Date()).format('MM'), 10);
    const thisYear = parseInt(moment(new Date()).format('YY'), 10);
    const splitMonth = parseInt(splitExpiry[0], 10);
    const splitYear = parseInt(splitExpiry[1], 10);
    const validExpiry =
      splitMonth <= 12 &&
      splitMonth > 0 &&
      (splitYear > thisYear ||
        (splitYear === thisYear && splitMonth >= thisMonth));
    const validCvc =
      (issuer === 'amex' && cvc.length === 4) ||
      (issuer !== 'amex' && cvc.length === 3);
    this.setState({ validCreditCardInfo: false, validExpiry, validCvc });

    return validCredit && validExpiry && validCvc;
  }

  getQuestionAnswerObject(
    bookingQuestion?: IReduceBookingQuestion,
    contactQuestion?: IMainContactQuestions
  ) {
    const { selectedCountryCode } = this.state;
    const question = bookingQuestion
      ? bookingQuestion
      : contactQuestion
      ? contactQuestion
      : null;
    if (question) {
      if (
        question.DataType === 'DATE_AND_TIME' &&
        !!question.value &&
        !!question.time
      ) {
        return {
          Id: question.Id,
          Answer: [question.value + ' ' + question.time],
        };
      } else if (question.DataFormat === 'PHONE_NUMBER' && !!question.value) {
        if (
          selectedCountryCode &&
          selectedCountryCode.value &&
          validatePhoneNumber(question.value, selectedCountryCode.numLength)
        ) {
          return {
            Id: question.Id,
            Answer: [selectedCountryCode.value + question.value],
          };
        }
        question.isValid = false;
      } else if (
        !!question.value &&
        question.DataType !== 'DATE_AND_TIME' &&
        question.DataType !== 'OPTIONS'
      ) {
        const inputType = checkInputType(question.DataFormat);
        let validInput = true;
        if (inputType === 'email') {
          validInput = validateEmail(question.value);
        }
        if (validInput) {
          return {
            Id: question.Id,
            Answer: [question.value],
          };
        }
      } else if (!!question.selectValue && question.DataType === 'OPTIONS') {
        return {
          Id: question.Id,
          Answer: question.selectValue.map((q) => q.value),
        };
      } else {
        question.isValid = !(question.Required || question.value);
        return null;
      }
    }
    return null;
  }

  setMainContactAnswers() {
    const { contactDetails } = this.state;
    let isError = false;
    const questionArray: IQuestionAnswer[] = [];
    const newContactDetails: IQuestionAnswers | null = { ...contactDetails };
    if (newContactDetails) {
      Object.keys(newContactDetails).map((key) => {
        if (newContactDetails[key]) {
          const obj = this.getQuestionAnswerObject(
            undefined,
            newContactDetails[key]
          );
          if (obj) {
            questionArray.push(obj);
          } else {
            const hasError =
              newContactDetails[key].Required || newContactDetails[key].value
                ? true
                : false;
            newContactDetails[key].isValid = !hasError;
            isError = hasError;
          }
        }
      });
    }
    if (isError || questionArray.length === 0) {
      this.setState({ contactDetails: newContactDetails });
      return null;
    } else {
      return questionArray;
    }
  }
  changeSelectedCountryCode = async (countryCode: ICountryOption) => {
    this.setState({
      selectedCountryCode: countryCode,
    });
  };

  setBookingLineAnswers() {
    const { bookingQuestions, questions } = this.state;
    let isError = false;
    const allQuestionsCopy = questions ? { ...questions } : null;
    const bookingQuestionsCopy = bookingQuestions
      ? { ...bookingQuestions }
      : null;
    if (
      !!allQuestionsCopy &&
      !!allQuestionsCopy.BookingLines &&
      !!bookingQuestionsCopy
    ) {
      allQuestionsCopy.BookingLines.map((line) => {
        const cartItemId = line.CartItemId;
        if (!!line && !!cartItemId && !!bookingQuestionsCopy[cartItemId]) {
          line.AnsweredQuestions = [];
          Object.keys(bookingQuestionsCopy[cartItemId]).map((type) => {
            if (
              !!bookingQuestionsCopy[cartItemId] &&
              !!bookingQuestionsCopy[cartItemId][type]
            ) {
              bookingQuestionsCopy[cartItemId][type].map((quest) => {
                if (!!quest && quest.perBooking) {
                  const newObj = this.getQuestionAnswerObject(quest);
                  if (!!newObj && !!line.AnsweredQuestions) {
                    line.AnsweredQuestions.push(newObj);
                  } else {
                    isError = true;
                  }
                }
              });
            }
          });
          if (!!line && !!line.Guests) {
            line.Guests.map((guest) => {
              if (!!guest && !!guest.BookingId) {
                const bookingId = guest.BookingId;
                guest.AnsweredQuestions = [];
                Object.keys(bookingQuestionsCopy[cartItemId]).map((type) => {
                  if (
                    !!bookingQuestionsCopy[cartItemId] &&
                    bookingQuestionsCopy[cartItemId][type]
                  ) {
                    bookingQuestionsCopy[cartItemId][type].map((quest) => {
                      if (!!quest && quest.bookingId === bookingId) {
                        const newObj = this.getQuestionAnswerObject(quest);
                        if (!!newObj && !!guest.AnsweredQuestions) {
                          guest.AnsweredQuestions.push(newObj);
                        } else {
                          isError = true;
                        }
                      }
                    });
                  }
                });
              }
              if (!!guest && !!guest.Extras) {
                guest.Extras.map((extra) => {
                  if (!!extra && !!extra.BookingId) {
                    const bookingId = extra.BookingId;
                    extra.AnsweredQuestions = [];
                    Object.keys(bookingQuestionsCopy[cartItemId]).map(
                      (type) => {
                        if (
                          !!bookingQuestionsCopy[cartItemId] &&
                          bookingQuestionsCopy[cartItemId][type]
                        ) {
                          bookingQuestionsCopy[cartItemId][type].map(
                            (quest) => {
                              if (!!quest && quest.bookingId === bookingId) {
                                const newObj =
                                  this.getQuestionAnswerObject(quest);
                                if (!!newObj && !!extra.AnsweredQuestions) {
                                  extra.AnsweredQuestions.push(newObj);
                                } else {
                                  isError = true;
                                }
                              }
                            }
                          );
                        }
                      }
                    );
                  }
                });
              }
            });
          }
        }
      });
      if (isError) {
        return null;
      }

      const newBookingLines: IConfirmBookingLine[] = [];
      allQuestionsCopy.BookingLines.map((line) => {
        if (
          !!line &&
          !!line.CartItemId &&
          !!line.Product &&
          !!line.Guests &&
          /*!!line.Questions &&*/
          !!line.AnsweredQuestions
        ) {
          const obj: IConfirmBookingLine = {
            CartItemId: line.CartItemId,
            Product: line.Product,
            Guests: line.Guests.map((g) => ({
              BookingId: g.BookingId,
              Id: g.Id,
              Questions: g.AnsweredQuestions,
              Extras: g.Extras
                ? (g.Extras.map((e) => {
                    if (
                      !!e &&
                      !!e.BookingId &&
                      !!e.Id &&
                      !!e.AnsweredQuestions
                    ) {
                      return {
                        BookingId: e.BookingId,
                        Id: e.Id,
                        Questions: e.AnsweredQuestions,
                      };
                    }
                    return null;
                  }).filter((n) => !!n) as IBookingLineExtra[])
                : null,
            })),
            Questions: line.AnsweredQuestions,
          };
          newBookingLines.push(obj);
        } else if (
          !!line &&
          !!line.CartItemId &&
          !!line.Product &&
          !!line.Guests
        ) {
          const obj: IConfirmBookingLine = {
            CartItemId: line.CartItemId,
            Product: line.Product,
            Guests: line.Guests.map((g) => ({
              BookingId: g.BookingId,
              Id: g.Id,
              Questions: [],
              Extras: g.Extras
                ? (g.Extras.map((e) => {
                    if (!!e && !!e.BookingId && !!e.Id) {
                      return {
                        BookingId: e.BookingId,
                        Id: e.Id,
                        Questions: [],
                      };
                    }
                    return null;
                  }).filter((n) => !!n) as IBookingLineExtra[])
                : null,
            })),
            Questions: [],
          };
          newBookingLines.push(obj);
        }
      });

      if (!newBookingLines || newBookingLines.length === 0) {
        return null;
      } else {
        return newBookingLines;
      }
    }
    return null;
  }

  verifyCard = async (e: any) => {
    e.preventDefault();
    const {
      agreeTerms,
      signUpNewsLetter,
      contactDetails,
      checkedBooking,
      useThreeDS,
      finalYayPrice,
      validSaga,
    } = this.state;

    if (!agreeTerms) {
      this.setState({ agreeError: true });
      return;
    }

    if (this.state.sagaClubNumber) {
      if (!validSaga) {
        //this.setState({ agreeError: true });
        return;
      }
    }
    const validCard = finalYayPrice === 0 ? true : this.validateCreditCard();
    if (!validCard) {
      return;
    }

    if (!contactDetails) {
      return;
    }

    const { currency, lang } = this.props;

    const CardInfo: ICardInfo = {
      CardNumber: this.state.number.replace(/\s+/g, ''),
      CVC: this.state.cvc,
      ExpiryDate: this.state.expiry,
    };

    const bookingLines = this.setBookingLineAnswers();
    const maincontact = this.setMainContactAnswers();
    if (
      ((!!this.state.bookingQuestions && !bookingLines) ||
        (!!this.state.contactDetails && !maincontact)) &&
      !checkedBooking
    ) {
      return;
    }

    let bookingObject: IConfirmBooking | null = null;

    if (maincontact) {
      bookingObject = {
        CartId: this.state.cartId,
        Questions: {
          MainContactDetails: maincontact,
          BookingLines: bookingLines,
        },
        CardInfo,
        SignUpForEmails: signUpNewsLetter,
      };
    } else if (!!checkedBooking && checkedBooking.Status === 'RESERVED') {
      bookingObject = {
        CartId: this.state.cartId,
        CardInfo,
        SignUpForEmails: signUpNewsLetter,
      };
    }

    if (!bookingObject) {
      this.setState({ isLoading: false });
      return;
    }

    this.setState({ isLoading: true, bookingError: null });
    this.setState({ submitting: true });

    let reservedCart = await this.cartApi.checkoutCart(
      bookingObject,
      currency.value,
      lang
    );

    if (reservedCart?.BookingNumber && this.state.userFlybus) {
      this.setExternal(
        reservedCart.BookingNumber,
        this.state.userFlybus.PartnerBookingNumber,
        'Payment New',
        this.state.userFlybus.OrderId,
        '',
        this.state.sagaClubNumber
      );
    }

    if (bookingObject.CardInfo.CardNumber === '') {
      this.setState({ checkedBooking: reservedCart });
    } else if (useThreeDS) {
      if (!!reservedCart && !!reservedCart.BookingNumber) {
        const data = await this.cartApi.get3dSecureData(
          bookingObject,
          reservedCart.BookingNumber,
          currency.value,
          this.state.userFlybus?.PartnerBookingNumber ?? ''
        );

        if (data !== null && data.Success && !!data.RedirectForm) {
          console.log('redirecting to 3ds form');

          this.setState({ submitting: true });

          // replace page with redirect form
          document.body.innerHTML = data.RedirectForm;
          document.getElementsByTagName('form')[0].submit();
          return;
        } else if (data !== null && data.Success) {
          console.log('getting authorization without 3d redirect');
          this.setState({ submitting: false });
          this.confirmReservedBooking(
            reservedCart.BookingNumber,
            signUpNewsLetter,
            currency.value,
            null,
            lang
          );
        } else {
          // move reseved booking back to the cart
          this.setState({ submitting: false });
          const cart = await this.cartApi.moveBackToCart(
            ls.get('cartId'),
            reservedCart.BookingNumber,
            currency.value
          );

          if (cart) {
            this.props.setCart(cart);
          }
          const bookingError = {
            error: {
              BookingNumber: reservedCart.BookingNumber,
              DetailedErrorMessage: '',
              ErrorCode: '',
              ErrorMessage: this.props.localeData.cardNotVerified,
            },
          };
          this.setState({ bookingError });
        }
      }
    } else {
      // if use3dsecure is false
      this.confirmCheckout(true);
    }

    this.setState({ isLoading: false });
  };

  async finishHash(bookedCart: IBooking | IBookingError | null) {
    const { currency } = this.props;
    const { userFlybus } = this.state;

    if (bookedCart && isBooking(bookedCart)) {
      const existsIcelandairDb = await this.bookingApi.checkExternalBooking(
        bookedCart?.BookingNumber ?? ''
      );
      if (existsIcelandairDb == true && userFlybus) {
        if (bookedCart?.BookingLines) {
          //console.log('generated-start');
          this.setExternal(
            bookedCart.BookingNumber ?? 'New-error',
            userFlybus.PartnerBookingNumber,
            'Payment Completed',
            userFlybus.OrderId,
            '',
            ''
          );
          //go to my journey
          //const item = cartObject?.Items;
          //const product = item[0];
          const bookingLine = bookedCart.BookingLines;
          const prDetails = bookingLine[0].Product;
          if (!userFlybus?.LastName && bookedCart.MainContact) {
            this.updateUserFlybus(
              bookedCart.MainContact?.FirstName,
              bookedCart.MainContact.LastName,
              bookedCart.MainContact?.Email,
              bookedCart.Currency
            );
          }
          const hashResponse = await this.generateHash(
            userFlybus.OrderId,
            userFlybus.PartnerBookingNumber,
            bookedCart.BookingNumber ?? '',
            bookedCart?.Currency ?? currency.value,
            bookedCart?.CreatedOn?.toString() ?? '',
            prDetails?.Id.toString() ?? '0',
            prDetails?.Name ?? '0',
            bookedCart?.Invoice?.TotalAmountWithDiscount ?? 0
          );
          if (typeof hashResponse === 'string') {
            this.setExternal(
              bookedCart.BookingNumber ?? 'New-error',
              userFlybus.PartnerBookingNumber,
              'Payment Completed-return hash generated',
              userFlybus.OrderId,
              hashResponse,
              this.state.sagaClubNumber
            );
            this.setState({ dropdownOpen: true });
            this.setState({ hashReturn: hashResponse });
            //console.log('generated');
          } else {
            this.setExternal(
              bookedCart.BookingNumber ?? 'New-error',
              userFlybus.PartnerBookingNumber,
              'Payment Completed-fail return hash',
              userFlybus.OrderId,
              '',
              this.state.sagaClubNumber
            );
          }
        }
      } else {
        localStorage.removeItem('flybusData');
      }
    }
  }
  confirmReservedBooking = async (
    bookingNumber: string,
    signUpForEmails: boolean,
    currency: string,
    cardInfo: ICardInfo | null,
    lang: string
  ) => {
    const bookedCart = await this.cartApi.confirmCheckout(
      bookingNumber,
      signUpForEmails,
      currency,
      cardInfo,
      lang
    );

    if (!!bookedCart && isBooking(bookedCart)) {
      this.setState({ checkedBooking: bookedCart });
      this.registerAnalyticsPurchase(bookedCart);

      this.setCacheFlybusData();
      if (this.state.userFlybus && this.props.isIcelandair) {
        this.finishHash(bookedCart);
      }
      if (!!bookedCart && isBooking(bookedCart) && !!bookedCart.BookingNumber) {
        clearQueryParameters();
        setQueryParameterNoRefresh('bookingId', bookedCart.BookingNumber);
      }
      this.resetQuestionState();
      this.props.resetCart();
    } else if (
      !!bookedCart &&
      !isBooking(bookedCart) &&
      !!bookedCart.error.BookingNumber
    ) {
      setQueryParameterNoRefresh('bookingId', bookedCart.error.BookingNumber);
      setQueryParameterNoRefresh('error', 'true');
      const query = getQueryVariable('bookingId');
      if (query) {
        const errorBooking = await this.bookingApi.getBooking(
          query,
          currency,
          lang
        );
        const cart = await this.cartApi.moveBackToCart(
          ls.get('cartId'),
          bookingNumber,
          currency
        );
        // const cart = await this.cartApi.getCart(this.state.cartId, currency);
        if (cart) {
          this.props.setCart(cart);
        }
        this.setState({
          checkedBooking: errorBooking,
          bookingError: bookedCart,
        });
      }
    } else if (!!bookedCart && !!bookedCart.error) {
      setQueryParameterNoRefresh('error', 'true');

      this.setState({
        bookingError: bookedCart,
      });
    }
  };

  confirmCheckout = async (skipLoadingCheck?: boolean) => {
    if (this.state.isLoading && !skipLoadingCheck) {
      return;
    }
    const { agreeTerms } = this.state;
    let isError = false;
    if (!agreeTerms) {
      isError = true;
      this.setState({ agreeError: true });
    }
    const validCard =
      this.state.finalYayPrice === 0 ? true : this.validateCreditCard();
    // const validCard = this.validateCreditCard();

    if (!validCard) {
      isError = true;
    }
    const { contactDetails, signUpNewsLetter, checkedBooking } = this.state;
    const { currency, lang } = this.props;
    const cardNumber = this.state.number;
    const newCard = cardNumber.replace(/\s+/g, '');
    const CardInfo: ICardInfo = {
      CardNumber: newCard,
      CVC: this.state.cvc,
      ExpiryDate: this.state.expiry,
    };
    let bookingObject: IConfirmBooking | null = null;
    if (contactDetails) {
      const bookingLines = this.setBookingLineAnswers();
      const maincontact = this.setMainContactAnswers();
      if (
        (!!this.state.bookingQuestions && !bookingLines) ||
        (!!this.state.contactDetails && !maincontact)
      ) {
        isError = true;
      }
      if (isError) {
        return;
      } else {
        if (!!checkedBooking && checkedBooking.Status === 'RESERVED') {
          bookingObject = {
            CartId: this.state.cartId,
            CardInfo,
            SignUpForEmails: signUpNewsLetter,
          };
        } else {
          if (maincontact) {
            bookingObject = {
              CartId: this.state.cartId,
              Questions: {
                MainContactDetails: maincontact,
                BookingLines: bookingLines,
              },
              CardInfo,
              SignUpForEmails: signUpNewsLetter,
            };
          }
        }
        if (bookingObject) {
          this.setState({ isLoading: true, bookingError: null });
          const bookedCart = await this.cartApi.bookCart(
            bookingObject,
            currency.value,
            lang
          );

          if (!!bookedCart && isBooking(bookedCart)) {
            this.setState({ checkedBooking: bookedCart });
            this.registerAnalyticsPurchase(bookedCart);

            if (
              !!bookedCart &&
              isBooking(bookedCart) &&
              !!bookedCart.BookingNumber
            ) {
              setQueryParameterNoRefresh('bookingId', bookedCart.BookingNumber);
              this.setCacheFlybusData();
              if (this.state.userFlybus && this.props.isIcelandair)
                this.finishHash(bookedCart);
            }
            this.resetQuestionState();
            this.props.resetCart();
          } else if (
            !!bookedCart &&
            !isBooking(bookedCart) &&
            !!bookedCart.error.BookingNumber
          ) {
            setQueryParameterNoRefresh(
              'bookingId',
              bookedCart.error.BookingNumber
            );
            setQueryParameterNoRefresh('error', 'true');
            if (this.state.userFlybus)
              this.setExternal(
                bookedCart.error.BookingNumber,
                this.state.userFlybus?.PartnerBookingNumber,
                bookedCart.error.ErrorMessage,
                this.state.userFlybus.OrderId,
                '',
                ''
              );
            const query = getQueryVariable('bookingId');
            if (query) {
              const errorBooking = await this.bookingApi.getBooking(
                query,
                currency.value,
                lang
              );
              // move reseved booking back to the cart
              const cart = await this.cartApi.moveBackToCart(
                ls.get('cartId'),
                bookedCart.error.BookingNumber,
                currency.value
              );
              if (cart) {
                this.props.setCart(cart);
              }
              this.setState({
                checkedBooking: errorBooking,
                bookingError: bookedCart,
              });
            }
          } else if (!!bookedCart && !!bookedCart.error) {
            setQueryParameterNoRefresh('error', 'true');

            this.setState({
              bookingError: bookedCart,
            });
          }
        }

        this.setState({ isLoading: false });
      }
    }
  };

  setExternal = async (
    bookingNumber: string,
    partnerBooking: string,
    status: string,
    orderId: string,
    returnHash: string,
    sagaClubNumber: string
  ) => {
    if (this.state.userFlybus && bookingNumber) {
      //console.log('setExternal ' + status);
      this.bookingApi.updateExternalBooking(
        bookingNumber,
        partnerBooking,
        status,
        orderId,
        returnHash,
        sagaClubNumber,
        null,
        this.props.lang
      );
    }
  };

  generateHash = async (
    externalOrderId: string,
    partnerBookingNumber: string,
    bookingNumber: string,
    currency: string,
    date: string,
    productId: string,
    productName: string,
    totalAmount: number
  ) => {
    const hashStringResponse = await this.productApi.generateHashFromBooking(
      externalOrderId,
      partnerBookingNumber,
      bookingNumber,
      currency,
      date,
      productId,
      productName,
      totalAmount,
      this.props.lang
    );
    if (hashStringResponse) {
      //this.state.hashString = hashStringResponse;
      this.setState({ hashString: hashStringResponse });
      return hashStringResponse;
    } else {
      return null;
    }
  };

  iskToUsd(exchangeRate: number | null, value: number) {
    if (!!exchangeRate && !!value && value !== 0) {
      return value / exchangeRate;
    }
    return value;
  }

  findAnalyticsCategory(productCode: string | null) {
    const { data } = this.props;
    if (!!data && !!productCode) {
      const product = data.allContentfulTour.edges.find(
        (edge) => edge.node.productNumber === productCode
      );
      if (product) {
        if (
          !!product.node &&
          !!product.node.analyticsCategory &&
          !!product.node.analyticsCategory.title
        ) {
          return product.node.analyticsCategory.title;
        } else if (
          !!product &&
          !!product.node &&
          !!product.node.category &&
          !!product.node.category[0] &&
          !!product.node.category[0].title
        ) {
          return product.node.category[0].title;
        }
      }
    }
    return null;
  }

  registerAnalyticsPurchase = (booking: IBooking) => {
    const { usdRate } = this.state;
    if (
      !!booking &&
      !!booking.BookingNumber &&
      booking.Status === 'CONFIRMED' &&
      !!booking.Invoice &&
      !!booking.Invoice.ProductInvoices
    ) {
      const currentCurrency = booking.Currency;
      const items = booking.Invoice.ProductInvoices.map((invoice) => {
        if (!!invoice && !!invoice.Items && !!invoice.Product) {
          const category = this.findAnalyticsCategory(invoice.ProductCode);
          return invoice.Items.map((item) => {
            if (
              !!item &&
              !!item.UnitPrice &&
              !!item.Quantity &&
              !!invoice.ProductCode
            ) {
              return {
                name: invoice.Product,
                sku: invoice.ProductCode + '-' + item.Title,
                price: item.UnitPriceWithDiscount
                  ? item.UnitPriceWithDiscount
                  : item.UnitPrice,
                quantity: item.Quantity,
                category,
              } as IAnalyticsItem;
            }
            return null;
          });
        }
        return null;
      });

      const array: IAnalyticsItem[] = [];
      Object.values(items).map((item) => {
        if (item) {
          item.map((innerArray) => {
            if (
              !!innerArray &&
              !!innerArray.name &&
              !!innerArray.quantity &&
              !!innerArray.sku &&
              !!innerArray.price &&
              innerArray.price !== 0
            ) {
              if (currentCurrency === 'ISK' && !!usdRate) {
                innerArray.price = innerArray.price / usdRate.ExchangeRate;
              }
              array.push(innerArray);
            }
          });
        }
      });

      if (
        !!booking.BookingNumber &&
        !!booking.TotalPrice &&
        !!array &&
        !!booking.Currency
      ) {
        const object: IAnalytics = {
          transactionId: booking.BookingNumber,
          transactionTotal:
            booking.Currency === 'ISK' && !!usdRate && booking.TotalPrice !== 0
              ? booking.TotalPrice / usdRate.ExchangeRate
              : booking.TotalPrice,
          currencyCode: booking.Currency === 'ISK' ? 'USD' : booking.Currency,
          transactionAffiliation: 'Reykjavík Excursions',
          transactionProducts: array,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'purchase',
          ...object,
        });
      }
    }
    return null;
  };

  renderBookingQuestions(
    bookingQuestions: IBookingQuestionsState,
    questions: IQuestions
  ) {
    const seo: ISEO = {
      title: 'Checkout',
      metaTitle: 'Checkout',
      metaDescription: 'Checkout',
      metaKeywords: 'Checkout',
      metaRobots: 'INDEX, FOLLOW',
      ogtype: 'website',
      ogtitle: 'Checkout',
    };
    return (
      <React.Fragment>
        {this.props.isIcelandair ? null : (
          <Meta
            seo={seo}
            langKey='en'
            title='Checkout'
            langSlugs={this.props.pageContext.langSlugs}
          />
        )}
        <CheckoutQuestions
          questionState={bookingQuestions}
          checkoutQuestions={questions}
          changeAnswers={this.changeBookingQuestions}
          mainContactHeader={this.props.localeData.mainContact}
          changeSelectedCountryCode={this.changeSelectedCountryCode}
          selectedCountryCode={this.state.selectedCountryCode}
        />
      </React.Fragment>
    );
  }

  changeMainContactDetails = (
    key: string,
    value: string | null,
    time: string | null,
    selectValue: ISelectValue[] | null
  ) => {
    const { contactDetails } = this.state;
    const { selectedCountryCode } = this.state;
    if (!!contactDetails && !!contactDetails[key]) {
      const newContactDetails = { ...contactDetails };
      if (!!newContactDetails && !!newContactDetails[key]) {
        const question = newContactDetails[key];
        if (question.DataType === 'DATE_AND_TIME') {
          if (value) {
            question.value = value;
            question.isValid = true;
          }
          if (time) {
            question.time = time;
            question.isValid = true;
          }
        } else if (
          (question.DataType === 'OPTIONS' && !!selectValue) ||
          (question.DataType === 'SHORT_TEXT' && question.SelectFromOptions)
        ) {
          question.selectValue = selectValue;
          question.isValid = true;
          //pre validate phone number
        } else if (question.Id === 'phoneNumber' && value) {
          const hasCountryCode =
            selectedCountryCode && selectedCountryCode.value;

          question.isValid = hasCountryCode
            ? validatePhoneNumber(value, selectedCountryCode.numLength)
            : false;
          question.value = value;
          //pre validate email
        } else if (
          question.DataType === 'SHORT_TEXT' &&
          question.Id === 'email' &&
          value
        ) {
          question.isValid = validateEmail(value);
          question.value = value;
        } else {
          question.value = value;
          question.isValid = true;
        }
      }
      this.setState({ contactDetails: newContactDetails });
    }
  };

  changeBookingQuestions = (
    key: string,
    type: string,
    index: number,
    questionType: DataType,
    value: string | null,
    time: string | null,
    selectValue: ISelectValue[] | null
  ) => {
    const { bookingQuestions } = this.state;
    if (
      !!bookingQuestions &&
      !!bookingQuestions[key] &&
      !!bookingQuestions[key][type] &&
      !!bookingQuestions[key][type][index]
    ) {
      const newAnswer = { ...bookingQuestions };
      if (newAnswer[key][type][index]) {
        if (questionType === 'DATE_AND_TIME') {
          if (value) {
            newAnswer[key][type][index].value = value;
            newAnswer[key][type][index].isValid = true;
          }
          if (time) {
            newAnswer[key][type][index].time = time;
            newAnswer[key][type][index].isValid = true;
          }
        } else if (questionType === 'OPTIONS') {
          newAnswer[key][type][index].selectValue = selectValue;
          newAnswer[key][type][index].isValid = true;
        } else {
          newAnswer[key][type][index].value = value;
          newAnswer[key][type][index].isValid = true;
        }
        this.setState({ bookingQuestions: newAnswer });
      }
    }
  };

  changeSignupNewsletter = () => {
    const { signUpNewsLetter } = this.state;
    if (signUpNewsLetter) {
      this.setState({ signUpNewsLetter: false });
    } else {
      this.setState({ signUpNewsLetter: true });
    }
  };

  changeAgreeTerms = () => {
    const { agreeTerms } = this.state;
    if (agreeTerms) {
      this.setState({ agreeTerms: false });
    } else {
      this.setState({ agreeTerms: true, agreeError: false });
    }
  };

  handleCouponChange(event: any) {
    this.setState({ couponCode: event.target.value.toUpperCase() });
  }
  handleSagaClubChange(event: any) {
    const sagaValue = event.target.value;
    if (sagaValue.toString().length <= 10) {
      this.setState({ sagaClubNumber: sagaValue });
      if (sagaValue.toString().length == 10) this.setState({ validSaga: true });
    } else {
      this.setState({ validSaga: false });
    }
  }
  handleGiftCardChange(event: any) {
    this.setState({ giftCardCode: event.target.value });
  }

  confirmCoupon = async () => {
    const { currency, lang } = this.props;
    const { cartId } = this.state;
    this.setState({ couponLoading: true });
    const newCart: [ICart | null, IPromoError | null] =
      await this.cartApi.applyCouponCode(
        cartId,
        this.state.couponCode,
        currency.value,
        lang
      );
    if (newCart) {
      if (newCart[1]) {
        const promoErrorMessage = this.getErrorMessageForCouponError(
          newCart[1]
        );
        this.setState({ promoErrorMessage });
        // setstate coupon error
      } else if (newCart[0]) {
        this.props.setCart(newCart[0]);
        this.setState({ couponCode: '', promoErrorMessage: null });
      }
    }
    this.setState({ couponLoading: false });
  };

  confirmGiftCard = async () => {
    const { currency, lang, localeData } = this.props;
    const { cartId, giftCardCode } = this.state;
    this.setState({ couponLoading: true });
    const newGiftCard: [ICart | null, IPromoError | null] =
      await this.cartApi.applyGiftCard(
        cartId,
        giftCardCode,
        currency.value,
        lang
      );
    if (newGiftCard) {
      if (newGiftCard[1]) {
        this.setState({
          giftCardErrorMessage: localeData.giftCardCodeErrorMessage,
        });
      } else if (newGiftCard[0]) {
        this.props.setCart(newGiftCard[0]);
        this.setState({ giftCardCode: '', giftCardErrorMessage: null });
        const cart2 = await this.cartApi.getCartByCheckout(
          this.state.cartId,
          this.props.currency.value,
          lang
        );
        if (cart2) {
          this.setState({ finalYayPrice: cart2.options[0].amount });
        }
      }
      this.setState({ couponLoading: false });
    }
  };

  getErrorMessageForCouponError(error: IPromoError) {
    // check if it uses the 'promoCode' error format

    // stop using this for now since we cant translate it
    /*     if (error.error.promoCode) {
      return error.error.promoCode.DetailedErrorMessage;
    } */

    // else handle the 'reason' error format from brókun
    const errorText = this.getCouponErrorReason(error.error);

    return this.props.data.allContentfulCouponErrorMessages.edges.filter(
      (x) => x.node.node_locale === this.props.pageContext.locale
    )[0].node[errorText];
  }

  // Bokun return the json error with a string at front so we have to split the
  // string and then parse it to a json format for easier use
  getCouponErrorReason(error: IError) {
    if (error.DetailedErrorMessage) {
      return camelize(
        JSON.parse(error.DetailedErrorMessage.split('Result ')[1]).fields.reason
      );
    }

    // return a generic error message if all else fails
    return 'fallbackMessage';
  }
  getPreviewImage(KeyPhoto: IPhoto) {
    if (KeyPhoto) {
      const photoIndex = KeyPhoto.OtherVersions.findIndex(
        (photo) => photo.Version === 'preview'
      );
      if (photoIndex) {
        return KeyPhoto.OtherVersions[photoIndex].Url;
      }
    }
    return undefined;
  }
  renderCartTable() {
    const { cartObject, localeData } = this.props;
    if (
      !!cartObject &&
      !!cartObject.Invoice &&
      !!cartObject.Invoice.ProductInvoices &&
      !!cartObject.Invoice.TotalAmount
    ) {
      const itemsByCartId = keyByIItem(cartObject.Items, 'CartItemId');
      return (
        <ul className={styles.checkoutList}>
          {cartObject.Invoice.ProductInvoices.map((product, productIndex) => {
            const image =
              cartObject &&
              cartObject.Items &&
              this.getPreviewImage(
                cartObject.Items[productIndex].Product.KeyPhoto
              );

            if (!!product && !!product.Items) {
              let pickupAddressLabel = null;
              {
                product.Items.map((item) => {
                  if (!!item && !!item.Title) {
                    if (
                      product.BookingType === 'ACTIVITIES' &&
                      item.Title.toUpperCase().startsWith('PICK')
                    ) {
                      pickupAddressLabel =
                        itemsByCartId[product.BookingId].PickupAddress.Name ??
                        '';
                    }
                  }
                });
              }
              const tourHasDiscount =
                product.TotalAmount !== product.TotalAmountWithDiscount;
              return (
                <li key={productIndex}>
                  <div>
                    {image ? (
                      <div className={styles.cartItem}>
                        <BackgroundImage
                          className={styles.image}
                          src={image ? image : ''}
                        />
                      </div>
                    ) : null}
                    <Link
                      langPath={this.props.pageContext.langPath}
                      to={this.getSlug(cartObject.Items, product)}
                    >
                      <Title tag='h2' theme={['sizeMobileSmall']}>
                        {product.Product
                          ? this.getProductName(product.Product)
                          : null}
                      </Title>
                    </Link>
                    <hr className={styles.dividerGray} />

                    <div className={styles.deleteFromCart}>
                      <button
                        onClick={() =>
                          this.removeItem(
                            product.BookingId,
                            product.BookingType
                          )
                        }
                      >
                        <Icon name='BIN' />
                        {localeData.removeFromCart}
                      </button>
                    </div>
                    {product.BookingType === TRANSPORT ? (
                      <div className={styles.extraInfo}>
                        <h2>
                          {itemsByCartId[product.BookingId].PickupAddress
                            .Name ?? ''}
                        </h2>
                        {itemsByCartId[product.BookingId]
                          .ReturnPickupAddress ? (
                          <img src={fortArrows} className={styles.fortArrow} />
                        ) : (
                          <img src={fortArrow} className={styles.fortArrow} />
                        )}
                        <h2>
                          {itemsByCartId[product.BookingId].DropoffAddress.Name}
                        </h2>
                      </div>
                    ) : null}
                    <h3 className={styles.subtitle}>
                      {localeData.tourDetails}
                    </h3>
                    <dl className={styles.tourdetailsInfo}>
                      {product.StartDate && (
                        <>
                          <dt>{localeData.dateAndTime}</dt>
                          <dd>
                            {moment(product.StartDate).format(
                              'dddd, MMMM DD, YYYY'
                            )}
                            {product.StartTime
                              ? ' ' +
                                localeData.dateAt +
                                ' ' +
                                product.StartTime
                              : null}
                            <br />
                            {product.ReturnDate && (
                              <>
                                {localeData.return}{' '}
                                {moment(product.ReturnDate).format(
                                  'dddd, MMMM DD, YYYY'
                                )}
                                {product.ReturnTime
                                  ? ' ' +
                                    localeData.dateAt +
                                    ' ' +
                                    product.ReturnTime
                                  : null}
                              </>
                            )}
                          </dd>
                          {pickupAddressLabel && (
                            <>
                              <dt>{localeData.pickUpDropOff}</dt>
                              <dd>{pickupAddressLabel}</dd>
                            </>
                          )}
                        </>
                      )}
                    </dl>
                  </div>

                  <div>
                    <h3 className={styles.subtitle}>
                      {localeData.priceBreakdown}
                    </h3>
                    <dl className={styles.priceBreakDown}>
                      {product.Items.map((item) => {
                        if (!!item && !!item.Title) {
                          // if pickup then add address?
                          return (
                            <>
                              <dt>
                                {item.Title} x {item.Quantity}
                              </dt>
                              <dd>
                                {item.TotalAmount
                                  ? formatPrice(
                                      item.TotalAmount,
                                      cartObject.Currency
                                    )
                                  : 0}{' '}
                                {item.Currency}
                              </dd>
                            </>
                          );
                        }
                        return null;
                      })}
                    </dl>
                    <hr className={styles.dividerGrayLong} />

                    {tourHasDiscount && (
                      <p className={styles.price}>
                        {localeData.price}
                        <span>
                          {product.TotalAmount &&
                            formatPrice(
                              product.TotalAmount,
                              cartObject.Currency
                            ) +
                              ' ' +
                              cartObject.Currency}
                        </span>
                      </p>
                    )}
                    {tourHasDiscount &&
                      product.TotalAmount &&
                      product.TotalAmountWithDiscount && (
                        <p className={styles.priceDiscount}>
                          {localeData.discount + ' '}
                          <span>
                            -{' '}
                            {formatPrice(
                              product.TotalAmount -
                                product.TotalAmountWithDiscount,
                              cartObject.Currency
                            ) +
                              ' ' +
                              cartObject.Currency}
                          </span>
                        </p>
                      )}
                    <p
                      className={
                        tourHasDiscount
                          ? cx(styles.price, styles.priceWithDiscount)
                          : styles.price
                      }
                    >
                      {localeData.totalPrice}
                      <span>
                        {product.TotalAmountWithDiscount &&
                          formatPrice(
                            product.TotalAmountWithDiscount,
                            cartObject.Currency
                          ) +
                            ' ' +
                            cartObject.Currency}
                      </span>
                    </p>
                  </div>
                </li>
              );
            }
            return null;
          })}
        </ul>
      );
    } else {
      return null;
    }
  }

  getProductName(name: string) {
    // check if the name starts with x -
    // note this happens because of how product manager handle combo tours in bokun
    let fixedName = name;
    if (name.startsWith(XSTART)) {
      fixedName = name.split('- ')[1];
    }

    return fixedName;
  }

  renderCartItems() {
    const { cartObject, localeData, isIcelandair, locale } = this.props;
    const { promoErrorMessage, giftCardErrorMessage } = this.state;

    if (!!cartObject && !!cartObject.Items) {
      return (
        <>
          <div>
            <h1 className={styles.mainTitle}>{localeData.checkoutMainTitle}</h1>
            <p className={styles.subheader}>
              {cartObject.Items.length}{' '}
              {cartObject.Items.length > 1
                ? localeData.checkoutSubtitleItem.plural
                : localeData.checkoutSubtitleItem.singular}{' '}
              {localeData.checkoutSubtitleText}
            </p>
          </div>
          <div className={styles.tableSection}>
            {this.renderCartTable()}
            {isIcelandair ? (
              <div className='columns'>
                <div className='column'>
                  <Title tag='h3' theme={['sizeDesktopSmall']}>
                    {localeData.sagaTitle}
                  </Title>
                  <label htmlFor='promo' className='sr-only'>
                    Saga Club number
                  </label>
                  <input
                    id='promo'
                    type='number'
                    //className={cx(styles.input)}
                    className={cx(styles.input, 'fs-block', {
                      [styles.errorField]:
                        this.state.sagaClubNumber &&
                        this.state.sagaClubNumber.length != 10,
                    })}
                    placeholder={localeData.sagaInput}
                    value={this.state.sagaClubNumber ?? null}
                    onChange={(event) => this.handleSagaClubChange(event)}
                  />
                </div>
              </div>
            ) : null}
            <div className='columns'>
              <div className='column'>
                <label htmlFor='promo' className='sr-only'>
                  {localeData.promoCodePlaceholder}
                </label>
                <input
                  id='promo'
                  type='text'
                  className={cx(styles.input)}
                  placeholder={localeData.promoCodePlaceholder}
                  value={this.state.couponCode}
                  onChange={(event) => this.handleCouponChange(event)}
                />
                <button
                  className={styles.promoCodeButton}
                  onClick={this.confirmCoupon}
                >
                  {localeData.promoCodeButton}
                </button>
                {promoErrorMessage ? (
                  <div className={styles.promoErrorMessage}>
                    {promoErrorMessage}
                  </div>
                ) : null}
              </div>
            </div>

            {ferdagjofinFlag ? (
              <div className='columns'>
                <div className='column'>
                  <label htmlFor='ferdagjof' className='sr-only'>
                    Ferðagjöf
                  </label>
                  <input
                    id='ferdagjof'
                    type='text'
                    className={cx(styles.input)}
                    placeholder={localeData.giftCardCodePlaceholder}
                    value={this.state.giftCardCode}
                    onChange={(event) => this.handleGiftCardChange(event)}
                  />
                  <button
                    className={styles.promoCodeButton}
                    onClick={this.confirmGiftCard}
                  >
                    {localeData.giftCardCodeButton}
                  </button>
                  {giftCardErrorMessage ? (
                    <div className={styles.promoErrorMessage}>
                      {localeData.giftCardCodeErrorMessage}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </>
      );
    }
    return null;
  }

  removeItem = async (cartItemId: number, BookingType: string | null) => {
    const { cartId } = this.state;
    const { currency, lang, localeData } = this.props;
    if (confirm(localeData.deleteConfirmation)) {
      if (!!cartId && !!cartItemId) {
        const cart = await this.cartApi.removeFromCart(
          cartId,
          cartItemId,
          currency.value,
          BookingType,
          lang
        );
        if (cart) {
          this.props.setCart(cart);
        }
      }
    }
  };

  // CreditCard
  handleCreditCardCallback = (type: CallbackArgument, isValid: boolean) => {
    if (isValid) {
      this.setState({ issuer: type.issuer });
    }
    if (isValid !== this.state.validCredit) {
      this.setState({ validCredit: isValid });
    }
  };

  setCardProp = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const fieldName = event.target.name as any;
      const fieldValue = event.target.value as any;
      if (fieldName === 'cvc') {
        this.setState({ validCvc: true });
      }
      if (fieldName === 'expiry') {
        this.setState({ validExpiry: true });
      }
      if (fieldName === 'number') {
        this.setState({ validCreditCardInfo: true });
      }
      this.setState({
        [fieldName]: fieldValue,
      } as Pick<IState, keyof IState>);
    }
  };

  renderCardInfo(errorMessage?: string) {
    const {
      name,
      cvc,
      expiry,
      number: numb,
      issuer,
      agreeTerms,
      signUpNewsLetter,
      agreeError,
      validCreditCardInfo,
    } = this.state;
    const { windowWidth, cartObject, localeData, isIcelandair } = this.props;

    return (
      <div ref={(elem) => (this.paymentDetails = elem)}>
        <div>
          <h2 className={styles.subtitle}>{localeData.paymentSelection}</h2>
        </div>
        {this.state.finalYayPrice !== 0 ? (
          <CheckoutCreditCard
            name={name}
            cvc={cvc}
            validCvc={this.state.validCvc}
            expiry={expiry}
            validExpiry={this.state.validExpiry}
            number={numb}
            validNumber={this.state.validCredit}
            issuer={issuer}
            handleCreditCardCallback={this.handleCreditCardCallback}
            setCardProp={this.setCardProp}
            isMobile={windowWidth <= windowSizes.header}
            validCreditCardInfo={validCreditCardInfo}
          />
        ) : null}
        <div className={styles.divider} />
        {!!cartObject && !!cartObject.Items && (
          <div
            data-cy='Checkout-Total-Price'
            className={styles.checkoutTotalPrice}
          >
            {!!cartObject.Discount &&
            cartObject.Discount &&
            cartObject.PromoCode ? (
              <p className={cx(styles.price, styles.priceDiscount)}>
                {cartObject.PromoCode ? cartObject.PromoCode : null}
                <span>
                  - {formatPrice(cartObject.Discount, cartObject.Currency)}{' '}
                  {cartObject.Currency}
                </span>
              </p>
            ) : null}
            {!!cartObject.Discount &&
            cartObject.Discount > 0 &&
            !!cartObject.TotalPriceWithDiscount ? (
              <React.Fragment>
                <p
                  className={
                    this.state.finalYayPrice <
                      cartObject.TotalPriceWithDiscount &&
                    this.state.finalYayPrice !== -1
                      ? cx(
                          styles.priceLineThrough,
                          styles.finalPriceLineThrough
                        )
                      : cx(styles.price, styles.finalPrice)
                  }
                >
                  {localeData.finalPrice}
                  <span>
                    {formatPrice(
                      cartObject.TotalPriceWithDiscount,
                      cartObject.Currency
                    )}{' '}
                    {cartObject.Currency}
                  </span>
                </p>
                {this.state.finalYayPrice < cartObject.TotalPriceWithDiscount &&
                this.state.finalYayPrice !== -1 &&
                ferdagjofinFlag ? (
                  <p className={cx(styles.price, styles.finalPrice)}>
                    {localeData.finalPrice}
                    <span>
                      {formatPrice(
                        this.state.finalYayPrice,
                        cartObject.Currency
                      )}{' '}
                      {cartObject.Currency}
                    </span>
                  </p>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p
                  className={
                    this.state.finalYayPrice < cartObject.TotalPrice &&
                    this.state.finalYayPrice !== -1 &&
                    ferdagjofinFlag
                      ? cx(
                          styles.priceLineThrough,
                          styles.finalPriceLineThrough
                        )
                      : styles.price
                  }
                >
                  {localeData.finalPrice}{' '}
                  <span>
                    {formatPrice(cartObject.TotalPrice, cartObject.Currency)}{' '}
                    {cartObject.Currency}
                  </span>
                </p>
                {this.state.finalYayPrice !== -1 &&
                this.state.finalYayPrice < cartObject.TotalPrice &&
                ferdagjofinFlag ? (
                  <p className={styles.price}>
                    {localeData.finalPrice}{' '}
                    <span>
                      {formatPrice(
                        this.state.finalYayPrice,
                        cartObject.Currency
                      )}{' '}
                      {cartObject.Currency}
                    </span>
                  </p>
                ) : null}
              </React.Fragment>
            )}
          </div>
        )}
        <div>
          <CheckBox
            checked={signUpNewsLetter}
            toggleCheckbox={this.changeSignupNewsletter}
          >
            {localeData.signUpCheckbox}
          </CheckBox>
        </div>
        <div>
          <CheckBox
            checked={agreeTerms}
            toggleCheckbox={this.changeAgreeTerms}
            isError={agreeError}
          >
            {localeData.iHaveReadAndAgreedTo}{' '}
            {
              <Link
                langPath={this.props.pageContext.langPath}
                to='terms-and-conditions'
                className={cx(styles.link, styles.required)}
              >
                {localeData.termsAndConditions}
              </Link>
            }
          </CheckBox>
        </div>

        {errorMessage ? (
          <p className={styles.checkoutError}>{errorMessage}</p>
        ) : null}
        <div className={cx(styles.buttonSection, 'columns')}>
          <div className={styles.checkoutButton}>
            <Button disabled={this.state.isLoading} onClick={this.verifyCard}>
              {localeData.confirmButton}
            </Button>
            <br />
            {isIcelandair ? (
              <TransportCancelButton
                cartObject={cartObject}
                sagaClubNumber={this.state.sagaClubNumber}
              />
            ) : null}
          </div>
          <br />
        </div>
        {this.state.useThreeDS && this.state.submitting ? (
          <div className={styles.redirectText}>
            {localeData.threeDSecureRedirect}
          </div>
        ) : null}
      </div>
    );
  }

  renderConfirmationText() {
    const { localeData } = this.props;
    return (
      <div className={styles.paymentSuccessContainer}>
        <div className={styles.paymentSuccess}>
          <h2 className={styles.successTitle}>
            {localeData.paymentSuccessful}
          </h2>
          <img src={successCheck} className={styles.successIcon} alt='' />
        </div>
        <p>{localeData.bookingConfirmation}</p>
      </div>
    );
  }

  renderCheckoutItems(booking: IBooking) {
    const { localeData } = this.props;
    if (
      !!booking &&
      isBooking(booking) &&
      !!booking.Invoice &&
      !!booking.Invoice.TotalAmount &&
      !!booking.Invoice.ProductInvoices
    ) {
      return (
        <div className={cx(styles.section, 'checkout box')}>
          <div>
            <Title tag='h2' theme={['sizeMobileLarge', 'sizeDesktopLarge']}>
              {localeData.bookingSummary}
            </Title>
          </div>
          <ul className={styles.checkoutList}>
            {booking.Invoice.ProductInvoices.map((product) => {
              if (!!product && !!product.Items) {
                const tourHasDiscount =
                  product.TotalAmount !== product.TotalAmountWithDiscount;
                return (
                  <li>
                    <div className={styles.titleContainer}>
                      <Title
                        tag='h3'
                        theme={['sizeMobileMedium', 'sizeDesktopMedium']}
                      >
                        {product.Product}
                      </Title>
                    </div>
                    <h4 className={styles.subtitle}>
                      {localeData.tourDetails}
                    </h4>

                    <dl className={styles.tourdetailsInfo}>
                      {product.StartDate && (
                        <>
                          <dt>{localeData.dateAndTime}</dt>
                          <dd>
                            {moment(product.StartDate).format(
                              'dddd, MMMM DD, YYYY'
                            )}
                            {product.StartTime
                              ? ' ' +
                                localeData.dateAt +
                                ' ' +
                                product.StartTime
                              : null}
                            <br />
                            {product.ReturnDate && (
                              <>
                                {localeData.return}{' '}
                                {moment(product.ReturnDate).format(
                                  'dddd, MMMM DD, YYYY'
                                )}
                                {product.ReturnTime
                                  ? ' ' +
                                    localeData.dateAt +
                                    ' ' +
                                    product.ReturnTime
                                  : null}
                              </>
                            )}
                          </dd>
                        </>
                      )}
                    </dl>
                    <div>
                      <h4 className={styles.subtitle}>
                        {localeData.priceBreakdown}
                      </h4>

                      <dl className={styles.priceBreakDown}>
                        {product.Items.map((item) => {
                          if (!!item && !!item.Title) {
                            // if pickup then add address?
                            return (
                              <>
                                <dt>
                                  {item.Title} x {item.Quantity}
                                </dt>
                                <dd>
                                  {item.TotalAmount
                                    ? formatPrice(
                                        item.TotalAmount,
                                        this.props.currency.value
                                      )
                                    : 0}{' '}
                                  {item.Currency}
                                </dd>
                              </>
                            );
                          }
                          return null;
                        })}
                      </dl>
                      <hr className={styles.dividerGrayLong} />

                      {tourHasDiscount && (
                        <p className={styles.price}>
                          {localeData.price}
                          <span>
                            {product.TotalAmount &&
                              formatPrice(
                                product.TotalAmount,
                                this.props.currency.value
                              ) +
                                ' ' +
                                booking.Currency}
                          </span>
                        </p>
                      )}
                      {tourHasDiscount &&
                        product.TotalAmount &&
                        product.TotalAmountWithDiscount && (
                          <p className={styles.priceDiscount}>
                            {localeData.discount}{' '}
                            <span>
                              -{' '}
                              {formatPrice(
                                product.TotalAmount -
                                  product.TotalAmountWithDiscount,
                                this.props.currency.value
                              ) +
                                ' ' +
                                booking.Currency}
                            </span>
                          </p>
                        )}
                      <p
                        className={
                          tourHasDiscount
                            ? cx(styles.price, styles.priceWithDiscount)
                            : styles.price
                        }
                      >
                        {localeData.totalPrice}
                        <span>
                          {product.TotalAmountWithDiscount &&
                            formatPrice(
                              product.TotalAmountWithDiscount,
                              this.props.currency.value
                            ) +
                              ' ' +
                              booking.Currency}
                        </span>
                      </p>
                    </div>
                  </li>
                );
              }
              return null;
            })}
          </ul>
          <hr className={styles.divider} />

          <div className={styles.checkoutTotalPrice}>
            <p className={cx(styles.price, styles.finalPrice)}>
              {localeData.finalPrice}
              {booking.Invoice.TotalAmountWithDiscount ? (
                <span>
                  {formatPrice(
                    booking.Invoice.TotalAmountWithDiscount,
                    this.props.currency.value
                  )}{' '}
                  {booking.Currency}
                </span>
              ) : (
                <span>
                  {formatPrice(
                    booking.Invoice.TotalAmount,
                    this.props.currency.value
                  )}{' '}
                  {booking.Currency}
                </span>
              )}
            </p>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { cartObject } = reducers.cartReducers;
  const { locale, lang } = reducers.translationReducers;
  const { isIcelandair } = reducers.layoutReducer;
  return { currency, cartObject, locale, lang, isIcelandair };
};
export default connect(mapStateToProps, { setCart, resetCart })(
  withWindowSize(
    ({
      pageContext,
      currency,
      cartObject,
      setCart,
      resetCart,
      windowWidth,
      windowHeight,
      lang,
      locale,
      isIcelandair,
    }: IExternalProps & IWindowSizeProps) => (
      <StaticQuery
        query={graphql`
          query TourCategories2 {
            allContentfulTour {
              edges {
                node {
                  productNumber
                  productId
                  slug
                  category {
                    title
                  }
                  analyticsCategory {
                    title
                  }
                }
              }
            }
            allContentfulCouponErrorMessages {
              edges {
                node {
                  node_locale
                  invalidTravelTime
                  notFound
                  notApplicableToCart
                  expired
                  fallbackMessage
                }
              }
            }
            allContentfulTranslationsCheckout {
              edges {
                node {
                  ...TranslationsCheckout
                  firstName
                  lastName
                  yourEmailAddress
                  sagaTitle
                  sagaInput
                }
              }
            }
            allContentfulTranslationsVarious {
              edges {
                node {
                  ...TranslationsVarious
                  phoneNumber
                  nationality
                }
              }
            }
          }
        `}
        render={(data) => {
          return (
            <CheckoutCart
              localeData={
                data.allContentfulTranslationsCheckout.edges.filter(
                  (node: { node: { node_locale: string } }) => {
                    return node.node.node_locale === locale;
                  }
                )[0].node
              }
              localeData2={
                data.allContentfulTranslationsVarious.edges.filter(
                  (node: { node: { node_locale: string } }) => {
                    return node.node.node_locale === locale;
                  }
                )[0].node
              }
              windowHeight={windowHeight}
              windowWidth={windowWidth}
              data={data as IContentfulCategories}
              pageContext={pageContext}
              setCart={setCart}
              currency={currency}
              cartObject={cartObject}
              resetCart={resetCart}
              lang={lang}
              locale={locale}
              isIcelandair={isIcelandair}
            />
          );
        }}
      />
    )
  )
);
